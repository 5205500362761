import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DefaultLangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as i18nIsoCountries from 'i18n-iso-countries';
import * as i18nNationality from 'i18n-nationality';
import { forOwn, orderBy } from 'lodash';
import { ClientsApiService } from 'src/app/core/clients-api/clients-api.service';
import { ClientCommand } from 'src/app/service/dto/ClientCommand';
import { ToastService } from 'src/app/service/toast.service';
import { BaseDialog } from 'src/app/shared/components/base-dialog/base-dialog';
import { CustomValidator } from 'src/app/shared/validators/custom.validator';
import { PersonCommand } from '../../../service/dto/PersonCommand';
import Language from '../../../service/model/Language';
import NaturalPerson from '../../../service/model/NaturalPerson';
import Region from '../../../service/model/Region';
import NaturalPersonForm from '../../form/natural-person-form';

@Component({
  selector: 'mandates-create-natural-person',
  templateUrl: './create-natural-person.component.html',
  styleUrls: ['./create-natural-person.component.scss'],
})
export class CreateNaturalPersonComponent implements OnInit {
  @Input() clientId: string;
  @Input() person: NaturalPerson;
  @Output() formCancelled = new EventEmitter();
  @Output() formSaved = new EventEmitter();

  naturalPersonForm: NaturalPersonForm = new NaturalPersonForm();

  nationalities: { name: string; value: string }[];
  countries: { name: string; value: string }[];
  regionOptions: { name: string; value: Region }[] = [
    {
      name: 'REGION.FLANDERS',
      value: Region.FLANDERS,
    },
    {
      name: 'REGION.WALLONIA',
      value: Region.WALLONIA,
    },
    {
      name: 'REGION.BRUSSELS',
      value: Region.BRUSSELS,
    },
  ];
  booleanOptions: { name: string; value: boolean }[] = [
    {
      name: 'FORM-FIELD.YES',
      value: true,
    },
    {
      name: 'FORM-FIELD.NO',
      value: false,
    },
  ];
  languages = [
    { name: 'LANGUAGE.MULTI', value: Language.MULTI },
    { name: 'LANGUAGE.NL', value: Language.NL },
    { name: 'LANGUAGE.FR', value: Language.FR },
    { name: 'LANGUAGE.EN', value: Language.EN },
    { name: 'LANGUAGE.DE', value: Language.DE },
  ];

  constructor(
    public dialog: MatDialog,
    private translateService: TranslateService,
    private clientsApiService: ClientsApiService,
    private toastService: ToastService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.translateService.onDefaultLangChange.subscribe((event: DefaultLangChangeEvent) => {
      this.nationalities = this.getNationalities(event.lang);
      this.countries = this.getCountries(event.lang);
    });

    this.nationalities = this.getNationalities(this.translateService.defaultLang);

    this.countries = this.getCountries(this.translateService.getDefaultLang());

    this.vatForm
      .get('vatLiable')
      ?.valueChanges.subscribe((selectedValue) => this.onVatLiableChange(selectedValue));

    this.vatForm
      .get('vatNumber')
      ?.valueChanges.subscribe((selectedValue) => this.onVatNumberChange(selectedValue));

    this.personForm.get('nationality')?.valueChanges.subscribe((value) => {
      this.updateNationalNumberValidators(value);

      this.addressForm.patchValue({ country: value });
    });

    if (this.person != undefined) {
      this.naturalPersonForm.initFromNaturalPerson(this.person);

      if (this.person.companyNumber) {
        this.vatForm.get('companyNumber')?.disable();
      }

      if (this.person.vatNumber) {
        this.vatForm.get('vatNumber')?.disable();
      }
    }
  }

  get personForm(): UntypedFormGroup {
    return this.naturalPersonForm.form.get('person') as UntypedFormGroup;
  }

  get addressForm(): UntypedFormGroup {
    return this.naturalPersonForm.form.get('address') as UntypedFormGroup;
  }

  get vatForm(): UntypedFormGroup {
    return this.naturalPersonForm.form.get('vat') as UntypedFormGroup;
  }

  getNationalities(lang: string): { name: string; value: string }[] {
    const i18nNationalities = i18nNationality.getNames(lang);
    const nationalities: { name: string; value: string }[] = [];

    forOwn(i18nNationalities, (value, key) => {
      nationalities.push({ value: key, name: value });
    });
    return orderBy(nationalities, 'name', 'asc');
  }

  getCountries(lang: string): { name: string; value: string }[] {
    const i18nCountries = i18nIsoCountries.getNames(lang);
    const countries: { name: string; value: string }[] = [];

    forOwn(i18nCountries, (value, key) => {
      countries.push({ value: key, name: value });
    });
    return orderBy(countries, 'name', 'asc');
  }

  save() {
    this.naturalPersonForm.form.markAllAsTouched();

    if (this.naturalPersonForm.form.valid) {
      const person: PersonCommand = this.naturalPersonForm.toPersonDto();
      if (this.clientId) {
        const client: ClientCommand = { person };
        this.clientsApiService.updateClient(client, this.clientId).subscribe(
          (updatedClient) => {
            this.toastService.openClientUpdatedToast(updatedClient.person.getScreenName());
            this.formSaved.emit(updatedClient);
          },
          (error) => this.handleErrors(error)
        );
      } else {
        const client: ClientCommand = { person };
        this.clientsApiService.addClient(client).subscribe(
          (createdClient) => {
            this.toastService.openClientCreatedToast(createdClient.person.getScreenName());
            this.formSaved.emit(createdClient);
          },
          (error) => this.handleErrors(error)
        );
      }
    } else {
      this.toastService.openValidationToast();
    }
  }

  openCancelDialog(): void {
    if (this.person?.id) {
      const dialogRef = this.dialog.open(BaseDialog, {
        data: {
          title: 'DIALOG.CANCEL-FORM.DESCRIPTION-PERSON-UPDATE',
          description: '',
          cancelText: 'DIALOG.NO-CONTINUE',
          confirmText: 'DIALOG.YES-CANCEL',
          confirmColor: 'accent',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.formCancelled.emit();
        }
      });
    } else {
      this.router.navigate(['/']);
    }
  }

  onVatLiableChange(selectedValue: boolean) {
    if (selectedValue) {
      this.naturalPersonForm.form
        .get(['vat', 'vatNumber'])!
        .setValidators([Validators.required, CustomValidator.notEmpty, CustomValidator.validVat]);
      this.vatForm.get(['companyNumber'])!.setValidators([Validators.required]);
    } else {
      this.naturalPersonForm.form.get(['vat', 'vatNumber'])!.clearValidators();
      this.vatForm.get(['companyNumber'])!.clearValidators();
      this.naturalPersonForm.form
        .get(['vat', 'vatNumber'])!
        .setValidators([CustomValidator.validVat]);
    }
    this.naturalPersonForm.form.updateValueAndValidity();
    this.naturalPersonForm.form.markAsUntouched();
  }

  onVatNumberChange(selectedValue: string) {
    if (
      this.naturalPersonForm.form.get('vat.vatNumber')!.hasValidator(Validators.required) ||
      selectedValue
    ) {
      this.naturalPersonForm.form.get(['vat', 'inBelgium'])!.enable();
      this.naturalPersonForm.form.get(['vat', 'multipleRegions'])!.enable();
    } else {
      this.naturalPersonForm.form.get(['vat', 'inBelgium'])!.disable();
      this.naturalPersonForm.form.get(['vat', 'multipleRegions'])!.disable();
    }
  }

  private updateNationalNumberValidators(value: string) {
    if (value == 'BE') {
      this.personForm
        .get('nationalNumber')!
        .addValidators([CustomValidator.validBelgianNationalNumber]);
    } else {
      this.personForm
        .get('nationalNumber')!
        .removeValidators([CustomValidator.validBelgianNationalNumber]);
    }
    this.personForm.get('nationalNumber')!.updateValueAndValidity();
  }

  private async handleErrors(error: any) {
    if (error.errors && error.errors.length > 0) {
      const errors: { defaultMessage: string }[] = error.errors;
      errors.map((error) => {
        this.toastService.openErrorToast(error.defaultMessage);
      });
    } else if (error.status && error.status === 409) {
      this.toastService.openWarningToast(
        '',
        this.translateService.instant('TOAST.CLIENT-EXISTS-NATURAL-PERSON')
      );
    } else {
      this.toastService.openErrorToast(`[${error.status}: ${error.error}] ${error.message}`);
    }
  }
}
