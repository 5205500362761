import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appInitializer } from './app.initializer';
import { ClientsModule } from './clients/clients.module';
import { CustomHeaderInterceptor } from './core/intercept/auth.interceptor';
import { AuthService } from './core/authentication/auth.service';
import { CanDeactivateGuard } from './core/guards/can-deactivate.guard';
import { EmployeesModule } from './employees/employees.module';
import { MandatesModule } from './mandates/mandates.module';
import { SharedModule } from './shared/shared.module';
import { LanguageHeaderInterceptor } from './core/intercept/language-header.interceptor';
import { LanguageService } from './service/language.service';
import { LoadingInterceptor } from './core/intercept/loading.interceptor';
import { LoadingService } from './core/loading/loading.service';
import { LogoutPageComponent } from './core/components/logout-page/logout-page.component';
import { DropzoneUploadDirective } from './core/directives/dropzone-upload.directive';
import { BannerComponent } from './shared/components/banner/banner.component';

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CustomTranslateHttpLoader } from './service/custom-translate-http-loader';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new CustomTranslateHttpLoader(httpClient);
}

export const BE_DATE_FORMAT = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@NgModule({
  declarations: [AppComponent, LogoutPageComponent, BannerComponent, DropzoneUploadDirective],
  imports: [
    BrowserModule,
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.backendUrl],
        sendAccessToken: true,
      },
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    ClientsModule,
    MandatesModule,
    EmployeesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot({
      maxOpened: 5,
      autoDismiss: true,
      preventDuplicates: true,
      countDuplicates: true,
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
      deps: [LoadingService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageHeaderInterceptor,
      multi: true,
      deps: [LanguageService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHeaderInterceptor,
      multi: true,
    },
    /* {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    }, */
    CanDeactivateGuard,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: BE_DATE_FORMAT },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
