import { TranslateLoader } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import packageJson from '../../../package.json';

export class CustomTranslateHttpLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
  ) {
  }

  getTranslation(lang: string): Observable<any> {
    let version = environment.production ? packageJson.version : new Date().getTime();

    return this.http.get(`assets/i18n/${lang}.json?v=${version}`);
  }
}
